import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="infusionen" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Vitamin-C-Infusion`}</h1>
      <h3 {...{
        "id": "vitamin-c-infusion"
      }}>{`Vitamin-C-Infusion`}</h3>
      <p>{`Die biologischen Funktionen von Ascorbinsäure (Vitamin C) sind äußerst vielgestaltig:`}</p>
      <p>{`Einerseits ist Vitamin C essentieller Nährstoff, den wir täglich zuführen müssen, um Mangelerscheinungen vorzubeugen, wie Schleimhautblutungen, Müdigkeit, Schwäche, Reizbarkeit, Infektanfälligkeit…`}</p>
      <p>{`Andererseits ist Vitamin C ein hochwirksames Medikament der `}<a parentName="p" {...{
          "href": "/beschwerdebilder/vorsorge_praevention"
        }}>{`Präventivmedizin`}</a>{` und zur Behandlung von Infektionen, rheumatischen Erkrankungen, Allergien, `}<a parentName="p" {...{
          "href": "/beschwerdebilder/erschoepfung_stress"
        }}>{`Erschöpfung / Stress`}</a>{`, Asthma, Neurodermitis, zur Tumorprophylaxe und `}<a parentName="p" {...{
          "href": "/beschwerdebilder/begleitende-krebstherapie"
        }}>{`begleitende Krebstherapie`}</a>{` geeignet.`}</p>
      <p>{`Vitamin C ist das effektivste Antioxidans im menschlichen Blutplasma. Um schädigende Nebenwirkungen freier Radikaler einzugrenzen und dem oxidativen Stress entgegenzuwirken, brauchen wir hohe Dosen mit hoher Bioverfügbarkeit. Das erreichen wir nur mit Hochdosis- Infusionen.`}</p>
      <p>{`Wöchentliche Infusionen mit 7,5g - 15,0g Vitamin C zeigen häufig schon nach 5-6 Behandlungen positive Effekte.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      